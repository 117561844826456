import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'

import store from '@/store'
import router from '@/router'
export const baseURL = process.env.VUE_APP_BASEURL
export const docBaseUrl = process.env.VUE_APP_DOC_BASEURL
let loadingObj = null
// 创建axios 实例
export const Service = axios.create({
  timeout: 8000,
  baseURL: baseURL
  // headers: {
  //   'Content-type': 'multipart/form-data;chartset=utf-8'
  // }
})

//请求拦截
Service.interceptors.request.use((config) => {
  loadingObj = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  // config.headers['Content-type'] = 'multipart/form-data'
  config.headers['token'] = store.state.user.userinfo.token

  return config
})

//响应拦截
Service.interceptors.response.use(
  (response) => {
    loadingObj.close()
    //请求出错
    const messageDom = document.getElementsByClassName('el-message__content')[0]
    let msg
    if (response.data.code == 405) {
      msg = error.data.message || '登录过期，请重新登录'
      if (!messageDom || messageDom.textContent != msg) ElMessage.error(msg)
      store.commit('setUserinfo', {})
      router.push('/login')
    } else if (response.data.code != 200) {
      msg = response.data.message || '服务器出错'
      if (!messageDom || messageDom.textContent != msg) ElMessage.error(msg)
      return reject(response.data)
    }
    if (response.config.method === 'get') {
      if (!response.data) {
        msg = '获取数据失败，请刷新重试'
        if (!messageDom || messageDom.textContent != msg) ElMessage.error(msg)
        return reject(response.data)
      }
    }
    return response.data
  },
  (error) => {
    const messageDom = document.getElementsByClassName('el-message__content')[0]
    loadingObj.close()
    // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
    if (error.response) {
      const status = error.response.status
      const msg = error.response.data.message || '服务器出错'
      if (!messageDom || messageDom.textContent != msg) ElMessage.error(msg)
      if (status === 405) {
        localStorage.removeItem('userinfo')
        store.commit('setUserinfo', {})
        router.push('/login')
      } else {
        // console.log(error.response)
      }
    } else if (error.request) {
      // 请求已经成功发起，但没有收到响应
      // `error.request` 在浏览器中是 XMLHttpRequest 的实例，
      // 而在node.js中是 http.ClientRequest 的实例
      // console.log(error.request)
    } else {
      // 发送请求时出了点问题
      // console.log('Error', error.message)
    }
    return Promise.reject(error)
  }
)

// post 请求
export const post = (config) => {
  return Service({
    ...config,
    method: 'post',
    params: config.data
  })
}
export const postForData = (config) => {
  return Service({
    ...config,
    method: 'post',
    data: config.data
  })
}
export const postForBody = (config) => {
  return Service({
    ...config,
    method: 'post',
    data: config.data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// get 请求
export const get = (config) => {
  return Service({
    ...config,
    method: 'get',
    params: config.data
  })
}

// put 请求
export const put = (config) => {
  return Service({
    ...config,
    method: 'put',
    params: config.data
  })
}

//delete请求
export const del = (config) => {
  return Service({
    ...config,
    method: 'delete'
  })
}
