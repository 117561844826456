export const eOptionsType = {
  eText: 0,
  ePicture: 1
}

export const eQuestionFlag = {
  eChapter: 0,
  ePaper: 1
}

export const eQueType = {
  eSelect: 1,
  eAnswer: 2,
  eMultiSelect: 3
}

export const eRole = {
  eSuperManager: 1,
  eSecondAuditor: 2,
  eFirstAuditor: 3,
  eInserter: 4,
  eTeacher: 5, //教师
  eOperationManager: 6, //运营管理员
  eRealPaperManager: 7, //真题管理员,
  eInstitutionManager: 8, //机构管理员
  eStudentManager: 9 //学生管理员
}

export const eQuestionOperate = {
  eFirstAudit: 0,
  eSecondAudit: 1,
  eDetail: 2,
  eEdit: 3
}

export const eQuestionStatus = {
  eBeInserted: 1, //已录入
  eFirstAudit: 2, //待发布
  eSecondAudit: 3, //已发布
  eIsBack: 4, //已退回
  eBeBanned: 5 //已禁用
}

export const eQuestionListType = {
  eList: 0,
  eFirstAuditList: 1,
  eSecondAuditList: 2,
  eInsertedList: 3,
  eCustomList: 4
}

export const eSetType = {
  eMockPaper: 0,
  eSpecial: 1,
  eConcise: 2,
  eRealPaper: 3
}

//真题的试卷状态
export const eQuestionPaperStatus = {
  eUnconfirmed: 0, //未指定
  eConfirmed: 1 //已指定
}
