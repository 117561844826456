<template>
  <div class="container">
    <!-- 页面主体区域 -->
    <el-container style="height: 100%">
      <!-- 侧边栏 -->
      <el-aside class="custom-aside">
        <SideBar :isCollapse="isCollapse" />
      </el-aside>
      <el-container>
        <!-- 头部 -->
        <el-header>
          <HeaderBar />
        </el-header>
        <el-main>
          <AppMain />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import SideBar from './components/SideBar'
import HeaderBar from './components/HeaderBar'
import AppMain from '@/views/layout/components/AppMain'
export default {
  name: 'layout',
  components: {
    SideBar,
    HeaderBar,
    AppMain
  },
  setup() {
    const data = reactive({
      isCollapse: false
    })
    return {
      ...toRefs(data)
    }
  }
}
</script>
<style lang="less" scoped>
@import '/src/styles/variables.modules.less';
.container {
  height: 100%;
}
.el-header {
  width: 100%;
  --el-header-padding: 0;
  --el-header-height: @headerBarHeight;
}
.custom-aside {
  background-color: #333744;
  display: inline-block; /* 设置为内联块级元素 */
  width: auto; /* 设置宽度为自适应 */
  padding: 0; /* 取消内边距 */
  margin: 0; /* 取消外边距 */
}
.el-main {
  background-color: #eaedf1;
  padding: @appMainPadding;
}
</style>
