<template>
  <div class="tags-view-container">
    <el-scrollbar class="tags-view-wrapper">
      <div class="scrollbar-flex-content">
        <el-dropdown
          v-for="(tag, index) in tagsViewList"
          :key="tag.fullPath"
          trigger="contextmenu"
          size="small">
          <el-tag
            :closable="!isAffiix(tag)"
            @close="onCloseClick(index, tag)"
            class="tags-view-item"
            @click="onTagsClicked(tag.fullPath)"
            :effect="isActive(tag) ? 'dark' : 'plain'"
            type="info">
            {{ tag.title }}
          </el-tag>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onCloseRightClick(index)">
                关闭右侧
              </el-dropdown-item>
              <el-dropdown-item @click="onCloseOtherClick(index)">
                关闭其他
              </el-dropdown-item>
              <el-dropdown-item @click="onCloseAllClick(index)">
                关闭全部
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import store from '@/store'

const route = useRoute()

/**
 * 是否被选中
 */
const isActive = (tag) => {
  return tag.path === route.path
}
const isAffiix = (tag) => {
  return tag.meta && tag.meta.affix
}

/**
 * 关闭 tag 的点击事件
 */
const store = useStore()
const router = useRouter()

const tagsViewList = computed(() => {
  return store.state.app.tagsViewList
})

const onTagsClicked = (path) => {
  router.push(path)
}

const onCloseClick = (index, tag) => {
  store.commit('removeItemFromTagsView', {
    type: 'index',
    index
  })
  // 如果删除的是当前页面，自动定位到上一个页面
  if (isActive(tag)) {
    const tagsViewList = store.state.app.tagsViewList
    if (index == 0 && tagsViewList.length >= 1) {
      const pre_index = 0
      const pre_page = tagsViewList[pre_index]
      router.push(pre_page.fullPath)
    } else if (tagsViewList.length == 0) {
      // 如果是最后一个，定位到首页
      router.push('/')
    } else {
      const pre_index = index - 1
      const pre_page = tagsViewList[pre_index]
      router.push(pre_page.fullPath)
    }
  }
}

const onCloseRightClick = (index) => {
  var bFind = false
  for (var i = index; i < tagsViewList.length; i++) {
    if (tagsViewList[i].path == route.path) {
      bFind = true
    }
  }
  store.commit('removeItemFromTagsView', {
    type: 'right',
    index
  })
  if (index && bFind) {
    router.push(tagsViewList[tagsViewList.length - 1].fullPath)
  }
}

const onCloseOtherClick = (index) => {
  store.commit('removeItemFromTagsView', {
    type: 'other',
    index
  })
}

const onCloseAllClick = (index) => {
  store.commit('removeItemFromTagsView', {
    type: 'all',
    index: index
  })

  router.push('/')
}
</script>
<style lang="less" scoped>
.tags-view-container {
  display: flex;
  width: calc(100% - 20px);
  /* 设置宽度为父容器宽度减去 20px */

  .tags-view-wrapper {
    .scrollbar-flex-content {
      display: flex;
    }

    .tags-view-item {
      margin-left: 10px;
      cursor: pointer;

      &:first-of-type {
        margin-left: 0px;
      }

      &:last-of-type {
        margin-right: 10px;
      }
    }
  }
}
</style>
