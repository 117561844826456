import ButtonComp from './ButtonComp/ButtonComp.vue'

const components = {
  ButtonComp
}

const GlobalComponents = (app) => {
  Object.keys(components).forEach((key) => {
    app.component(`${key}`, components[key])
  })
}

export default GlobalComponents
