<template>
  <div>
    <el-tooltip v-if="tip && tip.length > 0" :content="tip">
      <el-button
        class="myButton"
        :class="{ 'button-mini': size === 'mini' }"
        v-btn
        @click="buttonClick"
        v-bind="buttonProps">
        <span v-if="text">{{ text }}</span>
      </el-button>
    </el-tooltip>
    <el-button
      v-else
      class="myButton"
      :class="{ 'button-mini': size === 'mini' }"
      v-btn
      @click="buttonClick"
      v-bind="buttonProps">
      <span v-if="text">{{ text }}</span>
    </el-button>
  </div>
</template>
<script>
export default {
  props: {
    tip: {
      type: [String],
      required: false,
      default: ''
    },
    text: {
      type: [String],
      required: false,
      default: ''
    },
    type: {
      type: [String],
      required: false,
      default: 'primary'
    },
    icon: {
      type: [String],
      required: false,
      default: ''
    },
    size: {
      type: [String],
      required: false,
      default: ''
    },
    disabled: {
      type: [Boolean],
      required: false,
      default: false
    }
  },
  computed: {
    buttonProps() {
      var _props = {
        type: this.type,
        disabled: this.disabled
      }
      if (this.icon.length > 0) {
        _props.icon = this.icon
      }
      if (this.size.length > 0) {
        _props.size = this.size
        if (this.size === 'mini') {
          _props.size = 'small'
        }
      }
      return _props
    }
  },

  data() {
    return {}
  },
  mounted() {
    if (this.text.length === 0) {
      var button = this.$el.getElementsByClassName('myButton')[0] // 获取<el-button>元素
      button.removeChild(button.lastChild)
    }
  },
  emits: ['click'],

  methods: {
    buttonClick() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="less" scoped></style>
