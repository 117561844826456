import { postForBody, get, post, postForData } from '../service.js'
import qs from 'qs'
import {
  eOptionsType,
  eQueType,
  eQuestionListType,
  eQuestionOperate,
  eQuestionStatus,
  eQuestionFlag
} from '@/util/enum.js'

/**
 * 格式化题目查询条件
 * @param {Array<number>} subjectId - 科目id
 * @param {Number} type - 题目类型
 * @param {string} content - 题目内容关键字
 * @param {Array<number>} status - 题目状态
 * @param {Array<number> | Number} points - 知识点id
 * @param {string} paperName - 试卷名称关键字
 * @param {Number} year - 年份
 * @param {string} school - 学校关键字
 * @param {Array<number>} questionPaperStatus - 题目试题状态：题目是否有指定试卷
 */
const formatQuestionQueryData = (
  subjectId,
  type,
  content,
  status,
  points,
  paperName,
  year,
  school,
  questionPaperStatus
) => {
  var data = {}
  if (subjectId) data.subjectId = subjectId
  if (type) data.type = type
  if (content && content.length > 0) data.content = content
  if (status) data.status = status
  if (points != null) data.points = points
  if (paperName && paperName.length > 0) data.paperName = paperName
  if (year && year.length > 0) data.year = year
  if (school && school.length > 0) data.school = school
  if (questionPaperStatus) data.questionPaperStatus = questionPaperStatus
  return data
}
/**
 *
 * @param {*} queryContent
 * @param {*} subjectId
 * @param {*} type
 * @param {*} status
 * @param {*} current
 * @param {*} pageSize
 * @param {*} flag 判断题目列表是否为试题录入员的题目列表
 * @returns
 */
export const getChapterQuestionListApi = (
  queryContent,
  subjectId,
  type,
  status,
  points,
  current,
  pageSize,
  flag = eQuestionListType.eInsertedList
) => {
  const formatData = formatQuestionQueryData(
    subjectId.length === 0 ? -1 : subjectId,
    type.length === 0 ? -1 : type,
    queryContent,
    status.length === 0 ? -1 : status,
    points,
    null,
    null,
    null,
    null
  )
  const data = {
    ...formatData,
    current,
    pageSize
  }
  let url = ''
  if (flag === eQuestionListType.eInsertedList) {
    url = '/inserted/question/chapter/list?' + qs.stringify(data, { arrayFormat: 'repeat' })
  } else {
    url = '/audit/question/first/chapter/list?' + qs.stringify(data, { arrayFormat: 'repeat' })
  }
  return get({
    url
  })
}
/**
 * 获取真题题目列表的API
 * @param {Array<number>} subjectId - 科目id
 * @param {string} paperName - 试卷名称关键字
 * @param {Number} type - 题目类型
 * @param {Number} year - 年份
 * @param {string} school - 学校关键字
 * @param {string} content - 题目内容关键字
 * @param {Array<number>} status - 题目状态
 * @param {Array<number> | Number} points - 知识点id
 * @param {Array<number>} questionPaperStatus - 题目试题状态：题目是否有指定试卷
 * @param {Number} current - 当前页数
 * @param {Number} pageSize - 每页数量
 * @param {Number} flag - eQuestionListType 判断题目列表是否为试题录入员的题目列表
 */
export const getPaperQuestionListApi = (
  subjectId,
  paperName,
  type,
  year,
  school,
  content,
  status,
  points,
  questionPaperStatus,
  current,
  pageSize,
  flag = eQuestionListType.eInsertedList
) => {
  const formatData = formatQuestionQueryData(
    subjectId,
    type,
    content,
    status,
    points,
    paperName,
    year,
    school,
    questionPaperStatus
  )
  const data = {
    ...formatData,
    current,
    pageSize
  }
  let url = ''
  if (flag === eQuestionListType.eInsertedList) {
    url = '/inserted/question/paper/list?' + qs.stringify(data, { arrayFormat: 'repeat' })
  } else {
    url = '/audit/question/first/paper/list?' + qs.stringify(data, { arrayFormat: 'repeat' })
  }
  return get({
    url
  })
}
/**
 * 获取题目列表指定查询条件对应index的id
 * @param {Number} questionFlag - 0:章节题，1：真题
 * @param {Number} index - 题目列表指定查询条件对应index
 * @param {Array<number>} subjectId - 科目id
 * @param {Number} type - 题目类型
 * @param {string} content - 题目内容关键字
 * @param {Array<number>} status - 题目状态
 * @param {Array<number> | Number} points - 知识点id
 * @param {string} paperName - 试卷名称关键字
 * @param {Number} year - 年份
 * @param {string} school - 学校关键字
 * @param {Array<number>} questionPaperStatus - 题目试题状态：题目是否有指定试卷
 */
export const getQuestionIdByQueryIndexApi = (
  questionFlag,
  index,
  subjectId,
  type,
  content,
  status,
  points,
  paperName,
  year,
  school,
  questionPaperStatus
) => {
  const formatData = formatQuestionQueryData(
    subjectId,
    type,
    content,
    status,
    points,
    paperName,
    year,
    school,
    questionPaperStatus
  )
  const data = {
    ...formatData,
    questionFlag,
    index
  }

  return get({
    url: '/audit/question/modify?' + qs.stringify(data, { arrayFormat: 'repeat' })
  })
}
export const getTypeOptionsApi = () => {
  return get({
    url: '/inserted/question/type/list'
  })
}

export const getStateOptionsApi = () => {
  return get({
    url: '/inserted/question/status/list'
  })
}

export const getChapterOptionsApi = (subjectId) => {
  return get({
    url: '/inserted/question/chapter/selector',
    data: { subjectId }
  })
}

export const getSectionOptionsApi = (chapterId) => {
  return get({
    url: '/inserted/question/section/selector',
    data: { chapterId }
  })
}

export const getPointOptionsApi = (chapterId) => {
  return get({
    url: '/inserted/question/point/selector',
    data: { chapterId }
  })
}

export const getPaperOptionsApi = (subjectId) => {
  return get({
    url: '/inserted/question/paper/selector',
    data: { subjectId }
  })
}

const formatQuestionInfo = (questionInfo, flag) => {
  const data = new FormData()
  data.append('subjectId', questionInfo.subjectId)
  data.append('subjectName', questionInfo.subjectName)
  data.append('sectionId', questionInfo.sectionId)
  data.append('content', questionInfo.content)
  data.append('optionsType', questionInfo.optionsType)
  data.append('optionType', questionInfo.optionsType)
  data.append('difficulty', questionInfo.difficulty)
  data.append('type', questionInfo.type)
  if (questionInfo.type === eQueType.eAnswer) {
    data.append('answer', questionInfo.answer)
    data.append('type', questionInfo.type)
  } else {
    questionInfo.answer.forEach((item) => {
      data.append('answer', item)
    })
    data.append('type', questionInfo.answer.length > 1 ? eQueType.eMultiple : eQueType.eSelect)
    questionInfo.options.forEach((item) => {
      data.append('options', item)
    })
  }
  if (questionInfo.points && questionInfo.points.length > 0) {
    questionInfo.points.forEach((item) => {
      data.append('pointIds', item[2])
    })
  }
  if (questionInfo.analyzing && questionInfo.analyzing.length > 0) data.append('analyzing', questionInfo.analyzing)
  questionInfo.contentImgs.forEach((item) => {
    data.append('contentImgs', item)
  })
  questionInfo.analyzingImgs.forEach((item) => {
    data.append('analyzingImgs', item)
  })
  questionInfo.answerImgs.forEach((item) => {
    data.append('answerImgs', item)
  })
  //真题题目
  if (flag === eQuestionFlag.ePaper) {
    if (questionInfo.year && questionInfo.year > 0) data.append('year', questionInfo.year)
    if (questionInfo.school && questionInfo.school.length > 0) data.append('school', questionInfo.school)
    if (questionInfo.number && questionInfo.number > 0) data.append('number', questionInfo.number)
    if (questionInfo.paperId) data.append('paperId', questionInfo.paperId)
    if (questionInfo.paperName) data.append('paperName', questionInfo.paperName)
  }

  return data
}
export const addChapterQuestionApi = (questionInfo) => {
  if (eQueType.eSelect === questionInfo.type) {
    if (questionInfo.answer.length > 1) {
      questionInfo.type = eQueType.eMultiSelect
    }
  }
  const data = formatQuestionInfo(questionInfo, eQuestionFlag.eChapter)

  return postForBody({
    url: '/inserted/question/chapterQuestion/add',
    data: data,
    timeout: 60000
  })
}

export const addExamQuestionApi = (questionInfo) => {
  const data = formatQuestionInfo(questionInfo, eQuestionFlag.ePaper)

  return postForBody({
    url: '/inserted/question/paperQuestion/add',
    data: data,
    timeout: 60000
  })
}

/**
 * 获取题目信息
 * @param {*} id
 * @param {*} flag 章节题目 or 真题题目
 */
export const getQuestionInfoApi = (id, flag) => {
  return get({
    url: '/common/question/info',
    data: {
      id,
      flag
    }
  })
}
/**
 *
 * @param {*} id
 * @param {*} flag 区分审核是否通过
 * @param {*} auditResult
 * @param {*} remark
 * @param {*} auditFlag 区分一级审核和二级审核
 * @returns
 */
export const questionAuditApi = (id, flag, auditResult, remark, auditFlag, subjectId) => {
  let url = ''
  if (auditFlag === eQuestionOperate.eFirstAudit) url = '/audit/question/first'
  else if (auditFlag === eQuestionOperate.eSecondAudit) url = '/audit/question/second'
  return post({
    url: url,
    data: {
      id,
      flag,
      auditResult,
      remark,
      subjectId
    }
  })
}

export const delQuestionApi = (id, flag) => {
  let url = '/audit/question/del'
  return get({
    url,
    data: {
      id,
      flag
    }
  })
}

export const changeQuestionStatusApi = (id, flag, status) => {
  let url = ''
  status === eQuestionStatus.eBeBanned ? (url = '/audit/question/enable') : (url = '/audit/question/banned')
  return get({
    url,
    data: {
      id,
      flag
    }
  })
}

export const updateQuestionInfoApi = (questionInfo, flag, isBack) => {
  var url = '/inserted/question'
  const data = formatQuestionInfo(questionInfo, flag)
  data.append('id', questionInfo.id)

  if (isBack) {
    if (flag === eQuestionFlag.eChapter) {
      url += '/backChapterQuestion/change'
    } else if (flag === eQuestionFlag.ePaper) {
      url += '/backPaperQuestion/change'
    }
  } else {
    if (flag === eQuestionFlag.eChapter) {
      url += '/chapterQuestion/change'
    } else if (flag === eQuestionFlag.ePaper) {
      url += '/paperQuestion/change'
    }
  }

  return postForData({
    url,
    data
  })
}
