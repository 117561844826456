import { getItem, setItem, removeItem } from '@/util/storage'
export default {
  namespace: true,
  //全局的状态初始值
  state: {
    questionListQueryInfo: getItem('questionListQueryInfo') || {}, // 题目列表页跳转到题目详情页记录的查询条件
    isSideBarExpand: true,
    tagsViewList: getItem('tagsViewList') || []
  },

  //计算State，获取对应的值
  getters: {
    cachedViews(state) {
      return state.tagsViewList
        .filter((item) => item.meta.isKeepAlive)
        .map((item) => item.name)
    }
  },

  //更新状态的唯一方法
  mutations: {
    setQuestionListQueryInfo(state, queryInfo) {
      state.questionListQueryInfo = queryInfo
      setItem('questionListQueryInfo', queryInfo)
    },
    clearQuestionListQueryInfo(state) {
      state.questionListQueryInfo = null
      removeItem('questionListQueryInfo')
    },
    toggleSideBarExpand(state) {
      state.isSideBarExpand = !state.isSideBarExpand
    },
    /**
     * 添加 tags
     */
    addItemToTagsViewList(state, tag) {
      const findIndex = state.tagsViewList.findIndex((item) => {
        return item.path === tag.path
      })
      // 处理重复
      if (findIndex == -1) {
        state.tagsViewList.push(tag)
        setItem('tagsViewList', state.tagsViewList)
      } else {
        state.tagsViewList[findIndex] = tag
      }
    },
    /**
     * 删除 tag
     * @param {type: 'other'||'right'||'index'||'all', index: index} payload
     */
    removeItemFromTagsView(state, payload) {
      if (payload.type === 'index') {
        state.tagsViewList.splice(payload.index, 1)
      } else if (payload.type === 'other') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        )
        state.tagsViewList.splice(0, payload.index)
        if (payload.index != 0) {
          // list第一位加入删除了的首页tag
          state.tagsViewList.unshift({
            fullPath: '/home',
            meta: { title: '首页', affix: true },
            name: 'home',
            params: {},
            path: '/home',
            query: {},
            title: '首页'
          })
        }
      } else if (payload.type === 'right') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        )
      } else if (payload.type === 'all') {
        state.tagsViewList = []
      }
      setItem('tagsViewList', state.tagsViewList)
    }
  },

  //可以异步操作，可以返回promise，更改数据还是传递到mutations
  actions: {},

  //数据较多，分模块
  modules: {}
}
