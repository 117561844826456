import { createStore } from 'vuex'
import user from '@/store/state/userinfo.js'
import tagsView from '@/store/state/tagsView.js'
import app from '@/store/state/app.js'
import getters from '@/store/getters.js'
export default createStore({
  //全局的状态初始值
  state: {},

  //计算State，获取对应的值
  getters,

  //更新状态的唯一方法
  mutations: {},

  //可以异步操作，可以返回promise，更改数据还是传递到mutations
  actions: {},

  //数据较多，分模块
  modules: {
    user,
    tagsView,
    app
  }
})
