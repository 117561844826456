export default {
  install: (app) => {
    const fun = function (evt) {
      let target = evt.target
      if (target.nodeName == 'SPAN') {
        target = evt.target.parentNode
      }
      target.blur()
    }
    app.directive('btn', {
      mounted(el) {
        el.addEventListener('focus', fun)
      },
      unmounted(el) {
        el.removeEventListener('focus', fun)
      }
    })
  }
}
