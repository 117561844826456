<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
const debounce = (fn, delay) => {
  let timer = null

  return function () {
    let context = this

    let args = arguments

    clearTimeout(timer)

    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const _ResizeObserver = window.ResizeObserver

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)

    super(callback)
  }
}
export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  setup() {
    // 切换为中文
    let locale = zhCn

    return {
      locale
    }
  }
}
</script>
<style>
/* flex布局类名
.flex-float {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex {
  display: flex;
  align-items: center;
} */
</style>
