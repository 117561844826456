<template>
  <div class="container" id="container" style="width: 180px">
    <!-- 侧边栏菜单区域 -->
    <el-menu
      active-text-color="#409bff"
      :background-color="$store.getters.cssVar.sideBarBg"
      text-color="#fff"
      :unique-opened="true"
      :collapse="!isSideBarExpand"
      :collapse-transition="false"
      :router="true"
      :default-active="activePath"
    >
      <!-- 一级菜单 -->
      <el-sub-menu
        :index="item.id + ''"
        v-for="item in menuList"
        :key="item.id"
      >
        <template #title>
          <!-- 图标 -->
          <el-icon>
            <component :is="item.field"></component>
          </el-icon>
          <!-- 文本 -->
          <span>{{ item.menu }}</span>
        </template>

        <!-- 二级菜单 -->
        <el-menu-item
          :index="subItem.path"
          v-for="subItem in item.subMenu"
          :key="subItem.number"
        >
          <template #title>
            <!-- 图标 -->
            <el-icon><Menu /></el-icon>
            <!-- 文本 -->
            <span>{{ subItem.name }}</span>
          </template>
        </el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { computed, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'sideBar',
  props: {
    isCollapse: {
      type: Boolean,
      required: true
    }
  },

  setup() {
    const route = useRoute()
    const store = useStore()
    const menuList = computed(() => {
      return store.state.user.menuList
    })
    const isSideBarExpand = computed(() => {
      var isSideBarExpand = store.state.app.isSideBarExpand
      var containerDiv = document.getElementById('container')
      if (containerDiv) {
        containerDiv.style.width = isSideBarExpand
          ? store.getters.cssVar.sideBarWidth
          : store.getters.cssVar.sideBarHideWidth
      }
      return store.state.app.isSideBarExpand
    })
    const data = reactive({
      activePath: '',
      menuList,
      isSideBarExpand
    })
    watch(
      route,
      () => {
        let isPathExist = data.menuList.some((menu) =>
          menu.subMenu.some((subMenu) => subMenu.path === route.path)
        )

        if (isPathExist) {
          data.activePath = route.path
        } else {
          data.activePath = null
        }
      },
      {
        immediate: true
      }
    )
    return {
      ...toRefs(data)
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu {
  border-right: none;
  :deep(.el-menu-item) {
    min-width: 150px;
  }
}
.toggle-button {
  background-color: #4a5064;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-align: end;
}
</style>
