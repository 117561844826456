import { post, get } from '../service.js'
import { aesECBEncrypt } from '@/util/myCrypto.js'
export const loginApi = (pin, word) => {
  let _pin = aesECBEncrypt(pin)
  let _word = aesECBEncrypt(word)
  // let _pin = pin
  // let _word = word
  return post({
    url: '/admin/login',
    data: { pin: _pin, word: _word }
  })
}

//获取用户列表
export const getUserListApi = (current, pageSize, query, role, state) => {
  var data = { current: current, pageSize: pageSize, name: query }
  if (role && role.length > 0) {
    data.role = role[0]
  }
  if (state && state.length > 0) {
    data.state = state[0]
  }
  return get({
    url: '/admin/user/list',
    data
  })
}

//获取目录
export const getMenuListApi = () => {
  return get({
    url: '/admin/menu/list'
  })
}

//修改用户状态
export const userStateChangeApi = (id) => {
  return get({
    url: `/admin/user/state?id=${id}`
  })
}

//获得角色列表
export const getRoleListApi = () => {
  return get({
    url: '/admin/role/list'
  })
}

export const getAuditRoleListApi = () => {
  return get({
    url: '/org/user/role'
  })
}
//添加角色
export const addUserApi = (pin, name, roleId) => {
  return post({
    url: `/admin/user/add?pin=${pin}&name=${name}&roleId=${roleId}`
  })
}

export const updateUserApi = (id, name, roleId) => {
  return post({
    url: `/admin/user/update?id=${id}&name=${name}&roleId=${roleId}`
  })
}

export const resetUserPasswordApi = (id) => {
  return post({
    url: `/admin/word/reset?id=${id}`
  })
}

export const getRoleIdByIdApi = (id) => {
  return get({
    url: `/admin/user/role?id=${id}`
  })
}

export const updatePwdApi = (word, fromerWord) => {
  let _word = aesECBEncrypt(word)
  let _fromerWord = aesECBEncrypt(fromerWord)
  return post({
    url: '/admin/change/word',
    data: {
      word: _word,
      fromerWord: _fromerWord
    }
  })
}

export const getQuestionNumAuditorListApi = () => {
  return get({
    url: '/admin/audit/question/list'
  })
}
