<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">
        <div>
          {{ item }}
        </div>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
// 生成数组数据
const breadcrumbList = ref([])
const getBreadcrumbList = () => {
  var list = []
  if (route.meta && route.meta.title) {
    list.push(route.meta.title)
  }
  if (route.query && route.query.name) {
    list.push(route.query.name)
  }
  breadcrumbList.value = list
}
// 监听路由变化时触发
watch(
  route,
  () => {
    getBreadcrumbList()
  },
  {
    immediate: true
  }
)

// 处理点击事件
const router = useRouter()
const onLinkClick = (item) => {
  // console.log(item)
  router.push(item.path)
}

// 如果将来需要进行主题替换，所以这里获取下动态样式
// const store = useStore();
// const linkHoverColor = ref(store.getters.cssVar.menuBg);
</script>

<style lang="less" scoped>
.el-breadcrumb {
  margin: 0;
}
</style>
