<template>
  <section class="app-main">
    <router-view v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="cachedViews">
          <component :is="Component" :key="route.name" />
        </keep-alive>
      </transition>
    </router-view>
  </section>
</template>
<script>
import { computed, reactive, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const route = useRoute()
    const cachedViews = computed(() => {
      return store.getters.cachedViews
    })
    const data = reactive({
      store,
      route,
      cachedViews
    })

    /**
     * 生成 title
     */
    const getTitle = (route) => {
      let title = ''
      if (!route.meta) {
        // 处理无 meta 的路由
        const pathArr = route.path.split('/')
        title = pathArr[pathArr.length - 1]
      } else {
        title = route.meta.title
      }
      return title
    }
    watch(
      route,
      (to, from) => {
        const { fullPath, meta, name, params, path, query } = to
        store.commit('addItemToTagsViewList', {
          fullPath,
          meta,
          name,
          params,
          path,
          query,
          title: getTitle(to)
        })
      },
      {
        immediate: true
      }
    )

    return {
      ...toRefs(data),
      getTitle
    }
  }
}
</script>

<style scoped>
@import '/src/styles/variables.modules.less';
.app-main {
  min-height: calc(100vh - headerBarHeight);

  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="less">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
