import CryptoJS from 'crypto-js/crypto-js'
const SECRET_KEY = 'xuwei.and.lili.6'

export const aesECBEncrypt = (plainText) => {
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)
  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

export const aesECBDecrypt = (encryptedText) => {
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)
  const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypted.toString(CryptoJS.enc.Utf8)
}
