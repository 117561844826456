import { createRouter, createWebHashHistory } from 'vue-router'
import layout from '../views/layout/index.vue'
import store from '@/store/index.js'
import home from '@/views/pages/Home.vue'
import { ElMessage } from 'element-plus'
const routes = [
  // 登录页面
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/Login.vue')
  },
  {
    path: '/',
    name: 'layout',
    component: layout,
    redirect: '/home',

    children: [
      {
        path: '/home',
        name: 'home',
        component: home,
        meta: {
          title: '首页',
          affix: true, // affix=true,tagViews右侧没有关闭按钮,
          isKeepAlive: false
        }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/pages/UserList.vue'),
        meta: {
          title: '用户管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/subjectList',
        name: 'subjectList',
        component: () => import('../views/pages/Subject/SubjectList.vue'),
        meta: {
          title: '科目列表',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/subject',
        name: 'subject',
        component: () => import('../views/pages/Subject/Subject.vue'),
        meta: {
          title: '科目详情',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/point',
        name: 'point',
        component: () => import('../views/pages/Point/Point.vue'),
        meta: {
          title: '知识点详情',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/pointList',
        name: 'pointList',
        component: () => import('../views/pages/Point/PointList.vue'),
        meta: {
          title: '知识点管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/specialList',
        name: 'specialList',
        component: () => import('../views/pages/Set/SpecialList.vue'),
        meta: {
          title: '专项管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/special',
        name: 'special',
        component: () => import('../views/pages/Set/Special.vue'),
        meta: {
          title: '专项详情',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/realPaperList',
        name: 'realPaperList',
        component: () => import('../views/pages/Set/RealPaperList.vue'),
        meta: {
          title: '真题管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/realPaper',
        name: 'realPaper',
        component: () => import('../views/pages/Set/RealPaper.vue'),
        meta: {
          title: '真题试卷详情',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/addQuestion',
        name: 'addQuestion',
        component: () => import('../views/pages/Question/AddQuestion.vue'),
        meta: {
          title: '添加题目',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/questionList',
        name: 'questionList',
        component: () => import('../views/pages/Question/QuestionList.vue'),
        meta: {
          title: '题目列表',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/sectionQuestionList',
        name: 'sectionQuestionList',
        component: () => import('../views/pages/Question/SectionQuestionList.vue'),
        meta: {
          title: '章节题目列表',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/firstAudit',
        name: 'firstAudit',
        component: () => import('../views/pages/Question/FirstAudit.vue'),
        meta: {
          title: '一级审核',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/secondAudit',
        name: 'secondAudit',
        component: () => import('../views/pages/Question/SecondAudit.vue'),
        meta: {
          title: '二级审核',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/question',
        name: 'question',
        meta: {
          title: '题目详情',
          affix: false,
          isKeepAlive: false
        },
        component: () => import('../views/pages/Question/Question.vue')
      },
      {
        path: '/dictum',
        name: 'dictum',
        meta: {
          title: '文案管理',
          affix: false,
          isKeepAlive: true
        },
        component: () => import('../views/pages/Dictum.vue')
      },
      {
        path: '/swiper',
        name: 'swiper',
        component: () => import('../views/pages/Swiper.vue'),
        meta: {
          title: '轮播图管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/courseList',
        name: 'courseList',
        component: () => import('../views/pages/CourseList.vue'),
        meta: {
          title: '课程管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/mockPaperList',
        name: 'mockPaperList',
        component: () => import('../views/pages/Set/MockPaperList.vue'),
        meta: {
          title: '模考管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/mockPaper',
        name: 'mockPaper',
        component: () => import('../views/pages/Set/MockPaper.vue'),
        meta: {
          title: '模考试卷详情',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/conciseList',
        name: 'conciseList',
        component: () => import('../views/pages/Set/ConciseList.vue'),
        meta: {
          title: '精练管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/concise',
        name: 'concise',
        component: () => import('../views/pages/Set/Concise.vue'),
        meta: {
          title: '精练试卷详情',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/studentList',
        name: 'studentList',
        component: () => import('../views/pages/StudentList.vue'),
        meta: {
          title: '学员管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/institutionList',
        name: 'institutionList',
        component: () => import('../views/pages/InstitutionList.vue'),
        meta: {
          title: '机构管理',
          affix: false,
          isKeepAlive: true
        }
      },
      {
        path: '/paperAnswerDetail',
        name: 'paperAnswerDetail',
        component: () => import('../views/pages/Set/PaperAnswerDetail.vue'),
        meta: {
          title: '试卷作答详情',
          affix: false,
          isKeepAlive: false
        }
      },
      {
        path: '/learningSituation',
        name: 'learningSituation',
        component: () => import('../views/pages/LearningSituation/LearningSituation.vue'),
        meta: {
          title: '学情统计',
          affix: false,
          isKeepAlive: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

/**
 * to: 到哪个页面
 * from：从哪个页面
 * next：放行，只有执行next()页面才会进行跳转
 */
router.beforeEach((to, from, next) => {
  if (from.path != '/' && from.path != to.path) localStorage.setItem('fromPath', '/' + from.name)
  if (to.path === '/login') return next()
  if (!store.state.user.userinfo.token) return next('/login')
  const userPermissionPathList = store.state.user.userPermissionPathList
  if (!userPermissionPathList.some((permissionPath) => permissionPath === to.path)) {
    ElMessage.info('无权访问' + to.path)
    next(from.fullPath)
    return
  }
  return next()
})

export default router
