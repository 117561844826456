import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus, { ElDialog } from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/css/global.css'
import btn from '@/directives/btn.js'
import GlobalComponents from './components'
// 全局错误处理器
window.addEventListener('unhandledrejection', (event) => {
  if (process.env.VUE_APP_MODE === 'dev') {
    console.error('未捕获的Promise错误:', event.reason)
  }
})
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 弹出时不锁定滚动条, 防止弹出时抖动
ElDialog.props.lockScroll.default = false
ElDialog.props.alignCenter.default = true

app
  .use(btn)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .mount('#app', router.currentRoute.value.path === '/login' ? '#login' : '#app')
document.title = '后台管理'
GlobalComponents(app)
