import { eRole } from '@/util/enum'
import { getItem, setItem } from '@/util/storage.js'

const calculatePermissionPathList = (menuList, role) => {
  const pathList = [
    '/subject',
    '/special',
    '/realPaper',
    '/mockPaper',
    '/concise',
    '/point'
  ]
  var permissionPathList = menuList.reduce((paths, menu) => {
    menu.subMenu.forEach((subMenu) => {
      paths.push(subMenu.path)
      if (subMenu.path.endsWith('List')) {
        const path = subMenu.path.slice(0, -4)
        if (pathList.includes(path)) {
          paths.push(path)
        }
      }
    })

    return paths
  }, [])
  permissionPathList.push('/home')
  if (
    [
      eRole.eSuperManager,
      eRole.eFirstAuditor,
      eRole.eSecondAuditor,
      eRole.eInserter,
      eRole.eStudentManager,
      eRole.eTeacher
    ].includes(role)
  ) {
    permissionPathList.push('/question')
  }
  if ([eRole.eTeacher, eRole.eStudentManager].includes(role)) {
    permissionPathList.push('/paperAnswerDetail')
  }
  permissionPathList.push('/')
  if (permissionPathList.includes('/subject')) {
    permissionPathList.push('/sectionQuestionList')
  }
  return permissionPathList
}
export default {
  namespace: true,
  //全局的状态初始值
  state: {
    userinfo: getItem('userinfo') || {},
    menuList: getItem('menuList') || [], // 菜单列表
    userPermissionPathList: getItem('userPermissionPathList') || [] //用户权限路径列表
  },

  //计算State，获取对应的值
  getters: {
    isOfficialTeacher(state) {
      if (state.userinfo.role == eRole.eTeacher && state.userinfo.isOfficial) {
        return true
      } else {
        return false
      }
    }
  },

  //更新状态的唯一方法
  mutations: {
    setUserinfo(state, uInfo) {
      state.userinfo = uInfo
      setItem('userinfo', state.userinfo)
    },
    setUserPermissionPathList(state, pathList) {
      state.userPermissionPathList = pathList
      localStorage.setItem(
        'userPermissionPathList',
        JSON.stringify(state.userPermissionPathList)
      )
    },
    setMenuList(state, menuList) {
      state.menuList = menuList
      setItem('menuList', state.menuList)

      state.userPermissionPathList = calculatePermissionPathList(
        state.menuList,
        state.userinfo.role
      )
      setItem('userPermissionPathList', state.userPermissionPathList)
    }
  },

  //可以异步操作，可以返回promise，更改数据还是传递到mutations
  actions: {}
}
