<template>
  <div class="container">
    <div class="container-top">
      <div class="top-right">
        <el-tooltip :content="isSideBarExpand ? '收起侧边栏' : '展开侧边栏'">
          <div class="expand_icon" @click="toggleSidebar">
            <el-icon size="25px" style="color: #3e3e3e">
              <Fold v-if="isSideBarExpand" />
              <Expand v-else />
            </el-icon>
          </div>
        </el-tooltip>
        <BreadcrumbComp />
      </div>
      <div class="top-left">
        <div style="padding: 0 10px">
          <el-dropdown>
            <el-avatar icon="UserFilled" />
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item icon="HomeFilled" disabled>
                  {{ userInfo.name }}
                </el-dropdown-item>
                <el-dropdown-item icon="Avatar" disabled>
                  {{ userInfo.roleName }}
                </el-dropdown-item>
                <el-dropdown-item @click.stop="showUpdatePwdDialog">
                  修改密码
                </el-dropdown-item>
                <el-dropdown-item @click.stop="logout"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="container-bottom">
      <tags-view />
    </div>

    <!-- 修改密码弹窗 -->
    <el-dialog
      v-model="updatePwdDialogData.isVisible"
      title="修改密码"
      width="450px"
      @close="updatePwdDialogClosed"
    >
      <!-- 表单区域 -->
      <el-form
        :model="updatePwdDialogData.pwdFormData"
        :rules="updatePwdDialogData.pwdFormRules"
        ref="updatePwdFormRef"
        label-width="80px"
        @keyup.enter="submitUpdatePwd()"
      >
        <el-form-item label="原密码" prop="originalPwd">
          <el-input
            type="password"
            clearable
            show-password
            v-model="updatePwdDialogData.pwdFormData.originalPwd"
          />
        </el-form-item>
        <el-form-item label="新密码" prop="pwd1">
          <el-input
            type="password"
            clearable
            show-password
            v-model="updatePwdDialogData.pwdFormData.pwd1"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="pwd2">
          <el-input
            type="password"
            clearable
            show-password
            v-model="updatePwdDialogData.pwdFormData.pwd2"
          />
        </el-form-item>
      </el-form>
      <!-- 按钮区域 -->
      <template #footer>
        <div>
          <el-button type="primary" @click="submitUpdatePwd(updatePwdFormRef)">
            确定
          </el-button>
          <el-button @click="cancelUpdatePwd">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import store from '@/store'
import { useRouter } from 'vue-router'
import { updatePwdApi } from '@/util/request/userRequest.js'
import { confirmMessageBox } from '@/util/utils'
import TagsView from '@/components/TagsView/index.vue'
import BreadcrumbComp from '@/components/BreadcrumbComp'
export default {
  name: 'headerBar',
  components: {
    TagsView,
    BreadcrumbComp
  },
  computed: {
    userInfo() {
      return store.state.user.userinfo
    },
    isSideBarExpand() {
      return this.$store.state.app.isSideBarExpand
    }
  },

  data() {
    const router = useRouter()

    return {
      updatePwdDialogData: {
        isVisible: false,
        pwdFormData: {
          originalPwd: '',
          pwd1: '',
          pwd2: ''
        },
        pwdFormRules: {
          originalPwd: [
            { required: true, message: '此为必填项', trigger: 'blur' },
            { min: 6, message: '密码不少于6位', trigger: 'blur' }
          ],
          pwd1: [
            { required: true, message: '此为必填项', trigger: 'blur' },
            { min: 6, message: '密码不少于6位', trigger: 'blur' }
          ],
          pwd2: [
            { required: true, message: '此为必填项', trigger: 'blur' },
            { min: 6, message: '密码不少于6位', trigger: 'blur' }
          ]
        },
        isCollapse: false
      },
      router
    }
  },
  methods: {
    updatePwdDialogClosed() {
      this.cancelUpdatePwd()
    },
    cancelUpdatePwd() {
      this.updatePwdDialogData.isVisible = false
      this.$refs.updatePwdFormRef.resetFields()
    },
    showUpdatePwdDialog() {
      this.updatePwdDialogData.isVisible = true
    },
    async submitUpdatePwd() {
      const res = await new Promise((resolve) => {
        this.$refs.updatePwdFormRef.validate((valid) => {
          resolve(valid)
        })
      })

      if (!res) {
        return
      }

      if (
        this.updatePwdDialogData.pwdFormData.pwd1 !=
        this.updatePwdDialogData.pwdFormData.pwd2
      ) {
        ElMessage.warning('两次密码不一致')
        return
      }
      try {
        await updatePwdApi(
          this.updatePwdDialogData.pwdFormData.pwd1,
          this.updatePwdDialogData.pwdFormData.originalPwd
        )
        ElMessage.success('修改成功，请重新登录')
        localStorage.removeItem('userinfo')
        store.commit('setUserinfo', {})
        this.router.push('/login')
      } catch (error) {}
    },
    async logout() {
      try {
        await confirmMessageBox('是否确认退出登录?')
        localStorage.removeItem('userinfo')
        store.commit('setUserinfo', {})
        this.router.push('/login')
      } catch (error) {}
    },
    toggleSidebar() {
      store.commit('toggleSideBarExpand')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 80px;
  .container-top {
    justify-content: space-between;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    border-bottom: 1px solid #d8dce5;
    .top-right {
      display: flex;
      align-items: center;
      height: 100%;
      .expand_icon {
        height: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .expand_icon:hover {
        background-color: #f9f9f9;
      }
    }
  }
  .container-bottom {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
</style>
