import { getSubjectListApi } from '@/util/request/subjectRequest.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getPointOptionsApi, getChapterOptionsApi } from '@/util/request/questionRequest.js'
import { eQuestionStatus } from '@/util/enum.js'
import JSZip from 'jszip'
export const getTagTypeByQuestionStatus = (questionStatus) => {
  switch (questionStatus) {
    case eQuestionStatus.eSecondAudit:
      return 'success'
    case eQuestionStatus.eFirstAudit:
      return 'warning'
    case eQuestionStatus.eIsBack:
      return 'danger'
    case eQuestionStatus.eBeBanned:
      return 'info'
    case eQuestionStatus.eBeInserted:
      return ''
    default:
      return ''
  }
}
export const lazyLoadForPointProps = async (node, resolve) => {
  const { level } = node
  if (node.data.children) return
  let nodes
  let res
  try {
    if (level === 0) {
      res = await getSubjectListApi()
    } else if (level === 1) {
      res = await getChapterOptionsApi(node.data.value)
    } else if (level === 2) {
      res = await getPointOptionsApi(node.data.value)
    }
    if (level <= 2) {
      nodes = res.data.map((item) => {
        if (level === 0) {
          return {
            value: item.subjectId,
            label: item.name,
            leaf: false
          }
        } else {
          var obj = {
            value: item.label,
            label: item.value,
            leaf: false
          }
          if (level === 2) {
            obj.leaf = true
          }
          return obj
        }
      })
      resolve(nodes)
    }
  } catch (error) {}
}

export const confirmMessageBox = (msg) => {
  return new Promise((resolve, reject) => {
    ElMessageBox.confirm(msg, 'Warning', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      lockScroll: false,
      dangerouslyUseHTMLString: true
    })
      .then(() => {
        resolve(true)
      })
      .catch(() => {
        reject(false)
      })
  })
}

export const convertToPercentage = (number, fixedNum = 2) => {
  const percentage = (number * 100).toFixed(fixedNum) + '%'
  return percentage
}

/**
 * 从给定的URL中提取文件信息
 * @param {string} url - 文件的URL
 * @param {string} [returnType='all'] - 指定返回的信息类型，可选值为 'fileName', 'fileNameWithoutExtension', 'fileExtension', 或 'all'（默认）
 * @returns {object} - 包含文件信息的对象
 */
// 导出一个函数，用于获取文件详情
export const getFileDetails = (url, returnType = 'all') => {
  // 获取文件名
  const fileName = url.substring(url.lastIndexOf('/') + 1)
  // 获取文件名（不带扩展名）
  const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'))
  // 获取文件扩展名
  const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)

  // 根据returnType参数返回不同类型的值
  if (returnType === 'fileName') {
    return fileName
  } else if (returnType === 'fileNameWithoutExtension') {
    return fileNameWithoutExtension
  } else if (returnType === 'fileExtension') {
    return fileExtension
  } else {
    // 返回一个对象，包含文件名、文件名（不带扩展名）和文件扩展名
    return {
      fileName,
      fileNameWithoutExtension,
      fileExtension
    }
  }
}

export const downloadFile = async (docUrl, imageUrls = [], docName = '') => {
  const zip = new JSZip()
  let url
  let fileName
  if (docName.length === 0) {
    docName = getFileDetails(docUrl, 'fileNameWithoutExtension')
  }

  try {
    const docResponse = await fetch(docUrl)
    console.log('docResponse: ', docResponse)
    const docBlob = await docResponse.blob()
    if (imageUrls.length === 0) {
      url = URL.createObjectURL(docBlob)
      fileName = docName + '.docx'
    } else {
      zip.file(`${docName}.docx`, docBlob)
      // 添加图片文件
      const promises = imageUrls.map(async (imageUrl, index) => {
        const imageResponse = await fetch(imageUrl)
        const imageBlob = await imageResponse.blob()
        zip.folder('img').file(getFileDetails(imageUrl, 'fileName'), imageBlob)
      })

      await Promise.all(promises)
      const content = await zip.generateAsync({ type: 'blob' })
      url = URL.createObjectURL(content)
      fileName = docName + '.zip'
    }

    // 下载文件
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.log('error: ', error)
    ElMessage.error('文件下载失败')
  }
}

export const getCurrentVersion = () => {
  return require('../../package.json').version
}
